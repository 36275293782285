import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { SendCarrierData } from "../../api/endpointApi";
import ChatWithUs from "../home/ChatWithUs";

function ApplyForm() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  let params = useParams();
  const intialValue = {
    name: "",
    email: "",
    phone: "",
    jobRole: params.id,
    resume: "",
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    handleBlur: false,
    handleChange: false,
    initialValues: intialValue,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const responsePromise = SendCarrierData(values);
      const response = await responsePromise;
      if (response.data.success) {
        localStorage.setItem("Thankyou", true);
        navigate("/thankyou");
      } else {
        alert("Something wrong happened! Please go back");
      }
    },
  });
  return (
    <div className="flex justify-center my-8 ">
      <form
        className="flex flex-col gap-2 w-[80%] md:w-[40%]"
        onSubmit={handleSubmit}
        enctype="multipart/form-data"
      >
        <p className="font-extralight">
          YOUR NAME <span className="text-[#FF0000]">*</span>
        </p>
        <input
          onChange={handleChange}
          onBlur={handleBlur}
          values={values.name}
          className=" h-[40px] bg-[#d9d9d9] p-2 hover:border-1 hover:border-black"
          type="text"
          name="name"
        />
        <p className="font-extralight">
          YOUR EMAIL <span className="text-[#FF0000]">*</span>
        </p>
        <input
          onChange={handleChange}
          onBlur={handleBlur}
          values={values.email}
          className=" h-[40px] bg-[#d9d9d9] p-2 hover:border-1 border-black"
          type="email"
          name="email"
        />
        <p className="font-extralight">
          YOUR PHONE <span className="text-[#FF0000]">*</span>
        </p>
        {intialValue.resume}
        <input
          onChange={handleChange}
          onBlur={handleBlur}
          values={values.phone}
          className=" h-[40px] bg-[#d9d9d9] p-2 hover:border-1 border-black"
          type="tel"
          name="phone"
        />
        <p className="font-extralight">
          JOB ROLE <span className="text-[#FF0000]">*</span>
        </p>
        <select
          onChange={handleChange}
          onBlur={handleBlur}
          values={values.jobRole === "" ? params.id : values.jobRole}
          className=" h-[40px] bg-[#d9d9d9] p-2 hover:border-1 border-black"
          name="jobRole"
        >
          <option value={params.id} selected hidden>
            {params.id}
          </option>
          <option value="Sales">Sales</option>
          <option value="Marketing">Marketing</option>
          <option value="Technical">Technical</option>
        </select>
        <p className="font-extralight">
          UPLOAD YOUR RESUME <span className="text-[#FF0000]">*</span>
        </p>
        <input
          onChange={(e) => setFieldValue("resume", e.currentTarget.files[0])}
          onBlur={handleBlur}
          values={values.resume}
          className="mb-4"
          type="file"
          name="resume"
          accept=".pdf, .doc, .docx"
        />
        <button
          type="submit"
          className="h-[60px] bg-[#0894DE] text-white text-[30px] font-extralight hover:bg-[#007abc]"
        >
          {isSubmitting ? 'APPLYING...' : 'APPLY'}
        </button>
      </form>
      <ChatWithUs />
    </div>
  );
}

export default ApplyForm;
