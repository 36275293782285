import socialmedia from "../../images/social-media.svg";
import marketing from "../../images/marketing.svg";
import finance from "../../images/finance.svg";
import businesslead from "../../images/business-lead.svg";
import customersupport from "../../images/customer-support.svg";
export const WorkDomainData = [
  {
    image: socialmedia,
    name: "SOCIAL MEDIA",
  },
  {
    image: businesslead,
    name: "BUSINESS LEAD",
  },
  {
    image: finance,
    name: "FINANCE",
  },
  {
    image: marketing,
    name: "MARKETING",
  },
  {
    image: customersupport,
    name: "CUSTOMER SUPPORT",
  },
];
