import axios from "axios";
const BASE_URL = "https://email-form-data-services.onrender.com/api/email/";
const config = {
  Headers: {
    "Content-type": "Application/json",
  },
};
export async function ContactFormAPI(values) {
  const URL = `${BASE_URL}contactus`;
  try {
    const response = await axios.post(URL, values, config);
    return response;
  } catch (error) {
    return error.message;
  }
}

export async function SendCarrierData(values) {
  const URL = `${BASE_URL}careersapply`;
  try {
    const response = await axios.post(URL, values, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  } catch (error) {
    return error;
  }
}
